<template>
  <section class="age-categories">
    <div class="container">
      <hr />

      <div class="upper">
        <header>
          <h4>الفئات العمرية</h4>
        </header>
        <div class="row">
          <div class="col-lg-4">
            <div class="item">
              <span>ابتدائي</span>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="item">
              <span>متوسط</span>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="item">
              <span>ثانوي</span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <!-- <div class="lower">
        <div class="row">
          <div class="col-lg-6">
            <div class="item">
              <header>
                <h4>دمج التعليم</h4>
              </header>
              <div class="content">
                <p>
                  دمج التعليم في مجال العلوم والتكنولوجيا والهندسة والرياضيات:
                  تتوافق World Robot Olympiad - Virtual مع التركيز السعودي على
                  التعليم في مجال العلوم والتكنولوجيا والهندسة والرياضيات
                  (STEM). يندمج بسلاسة في المنظومة التعليمية في البلاد، مكملاً
                  البرامج الحالية في مجال الروبوتات والعلوم والتكنولوجيا
                  والهندسة والرياضيات في المدارس. وتوفر WRO-V منصة مشوقة وتحديات
                  للطلاب لتطبيق معارفهم النظرية، وتطوير مهاراتهم العملية،
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="item">
              <header>
                <h4>تطوير المهارات:</h4>
              </header>
              <div class="content">
                <p>
                  التعلم المستمر وتطوير المهارات: تشجع WRO-V في المملكة العربية
                  السعودية المشاركين على اعتماد التعلم المستمر وتطوير المهارات
                  المستمر في مجال الروبوتات. تعمل المسابقة كحجر الزاوية
                  للمشاركين لاستكشاف مفاهيم الروبوتات المتقدمة ولغات البرمجة
                  والتكنولوجيات الناشئة. وتحفزهم على البقاء على اطلاع بأحدث
                  الاتجاهات في مجال الروبوتات ومتابعة التعليم أو فرص العمل
                  المستقبلية في هذا المجال.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr /> -->
      <div class="requirement">
        <div class="row">
          <div class="col-md-6">
            <div class="right">
              <header>
                <h4>متطلبات المشاركة</h4>
              </header>
              <div class="d-flex">
                <div class="element d-flex">
                  <i class="fa-solid fa-laptop"></i>
                  <span>جهاز حاسب آلي</span>
                </div>
                <div class="element d-flex">
                  <i class="fa-solid fa-wifi"></i>
                  <span>إنترنت</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="left">
              <div class="element">
                <img src="../../assets/image15.png" alt="" />
                <p>تحتوي المنصة على عدد ٦ دروس للمبتدئين تفاعليه</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="description">
        <div class="row">
          <div class="col-md-6">
            <div class="right">
              <header>
                <h4>آلية المشاركة</h4>
              </header>
              <ul class="list-unstyled">
                <li>
                  يتكون الفريق المشارك من <span>طالبين (بنين / بنات) </span>
                </li>
                <li>يقوم الطالبين بتسجيل حسابين منفصلين</li>
                <li>
                  يقوم أحد الطلاب بإنشاء الحساب ودعوة الطالب الآخر للمشاركة في
                  الفريق
                </li>
                <li>
                  يستخدم الطلاب صفحة Game Page للتدريب قبل موعد النهائي –
                  ويمكنهم حفظ عدد لا نهائي من برمجياتهم للرجوع لها واستخدامها
                  <span>لاحقا</span>.
                </li>
                <li>
                  يقوم الطلاب بمحاولة حل أكبر عدد ممكن من المهام في أقل وقت
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="left">
              <header>
                <h4>يقام النهائي بنظام الأدوار</h4>
              </header>
              <ul class="list-unstyled">
                <li class="d-flex">
                  <img src="../../assets/icons/one.png" alt="" />
                  يشارك كافة الطلاب في الجولة الأولى
                </li>
                <li class="d-flex">
                  <img src="../../assets/icons/tow.png" alt="" />
                  يتأهل ٥٠٪ من الطلاب للجولة الثانية
                </li>
                <li class="d-flex">
                  <img src="../../assets/icons/three.png" alt="" />
                  يتأهل أفضل ١٠-١٥ فريق للجولة الثالثة والأخيرة
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="points">
        <div class="head">
          <h5>احتساب النقاط</h5>
          <h5>آلياً</h5>
        </div>
        <ul class="list-unstyled">
          <li>
            يقوم الطلاب بالاشتراك في الحدث الخاص بهم حسب الفئة العمرية من صفحة
            Events قبل موعد إطلاق الحدث ( الموعد <span>النهائي</span> ) عند
            <span>ظهوره</span> على المنصة.
          </li>
          <li>
            سيكون الدخول للمشاركة في صفحة Events
            <span>وستظهر الجولات ومواعيدها </span> ( في حال لم تكن الجولة
            الثانية مفعلة فيعني ذلك عدم التأهل للجولة التالية).
          </li>
          <li>
            على الطالبين تقسيم الوقت المتاح بينهم حيث أن الوقت المسموح باحتسابه
            كجولة مقبولة هو مجموع <span>وقت</span> الطالبين <span>بحيث</span> لا
            يتجاوز 120 ثانية.
          </li>
          <li>
            يمكن للطالبين تسليم أكثر من نتيجة خلال الوقت المسموح بالنظام لتسليم
            النتائج – وسيقوم النظام باحتساب أفضل مجموع بين الطالبين وأفضل وقت.
          </li>
          <li>
            ملاحظة – يقوم النظام بعدم اعتماد أي نتائج لا تحقق الشرط المطلوب
            <span> (مجموع وقت الطالبين يساوي أو اقل من ١٢٠ ثانية).</span>
          </li>
          <li>
            في الجولة الأخيرة – قد <span>يتم المطالبة</span>
            <span>بمناقشة الأوامر البرمجية (عن بعد)</span> للتأكد من كون الطالب
            هو الذي قام بالبرمجة ومتمكن منها وسيؤثر ذلك على الترتيب النهائي.
          </li>
        </ul>
      </div>
    </div>
    <footer>
      <div class="image">
        <img src="../../assets/image14.png" alt="" />
      </div>
      <div class="content">
        <h4>هل انت مستعد للتحدي؟</h4>

        <form class="submitCapetcha">
          <div
            class="g-recaptcha"
            data-sitekey="6LcDofonAAAAAJQWgOI_LrGiyy7gwZl-1HJ8rgDJ"
          ></div>
          <button class="" @click="supmit">سجل الان</button>
        </form>
        <!-- <a target="_blank" href="https://saudi.wro-v.com/web/sign-In"
          >سجل الان</a
        > -->
      </div>
    </footer>
  </section>
</template>

<script>
/* PLEASE DO NOT COPY AND PASTE THIS CODE. */ (function () {
  var w = window,
    C = "___grecaptcha_cfg",
    cfg = (w[C] = w[C] || {}),
    N = "grecaptcha";
  var gr = (w[N] = w[N] || {});
  gr.ready =
    gr.ready ||
    function (f) {
      (cfg["fns"] = cfg["fns"] || []).push(f);
    };
  w["__recaptcha_api"] = "https://www.google.com/recaptcha/api2/";
  (cfg["render"] = cfg["render"] || []).push("onload");
  w["__google_recaptcha_client"] = true;
  var d = document,
    po = d.createElement("script");
  po.type = "text/javascript";
  po.async = true;
  var m = d.createElement("meta");
  m.httpEquiv = "origin-trial";
  m.content =
    "Az520Inasey3TAyqLyojQa8MnmCALSEU29yQFW8dePZ7xQTvSt73pHazLFTK5f7SyLUJSo2uKLesEtEa9aUYcgMAAACPeyJvcmlnaW4iOiJodHRwczovL2dvb2dsZS5jb206NDQzIiwiZmVhdHVyZSI6IkRpc2FibGVUaGlyZFBhcnR5U3RvcmFnZVBhcnRpdGlvbmluZyIsImV4cGlyeSI6MTcyNTQwNzk5OSwiaXNTdWJkb21haW4iOnRydWUsImlzVGhpcmRQYXJ0eSI6dHJ1ZX0=";
  d.head.prepend(m);
  po.src =
    "https://www.gstatic.com/recaptcha/releases/0hCdE87LyjzAkFO5Ff-v7Hj1/recaptcha__en.js";
  po.crossOrigin = "anonymous";
  po.integrity =
    "sha384-ToIRK/fCxk6HZidjy8su24Pn+8FsKiX4QnuO0AfQZBcsd0m8DMaqafsc91h4byqU";
  var e = d.querySelector("script[nonce]"),
    n = e && (e["nonce"] || e.getAttribute("nonce"));
  if (n) {
    po.setAttribute("nonce", n);
  }
  var s = d.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(po, s);
})();
export default {
  name: "AgeCategoriesComponent",
  methods: {
    supmit(e) {
      e.preventDefault();
      const capetcha = document.getElementById("g-recaptcha-response").value;
      if (!capetcha) {
        return alert("من فضبك قم بالاختبار");
      }

      window.location.href = "https://saudi.wro-v.com/web/sign-In";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.age-categories {
  background: $main-color;
  padding: 50px 0 0;
  hr {
    margin: 30px 0 50px;
    background: #212121;
    height: 3px !important;
  }
  header {
    background: url("../../assets/image6.png");
    background-size: cover;
    width: 300px;
    padding: 20px;
    margin-bottom: 20px;
    h4 {
      color: #c0bbd1;
    }
  }
  .upper {
    .row {
      > div:not(:nth-of-type(2)) .item {
        background: var(
          --linear,
          linear-gradient(
            2deg,
            rgba(44, 30, 100, 0) -15.15%,
            rgba(51, 31, 103, 0.06) -1.77%,
            rgba(68, 32, 110, 0.24) 19.84%,
            rgba(97, 35, 122, 0.52) 47.62%,
            rgba(137, 38, 139, 0.91) 79.52%,
            #93278f 86.73%
          )
        );
      }
      > div:nth-of-type(2) .item {
        background: var(
          --linear-2,
          linear-gradient(
            2deg,
            rgba(44, 30, 100, 0) -15.15%,
            rgba(47, 30, 105, 0.05) -4.86%,
            rgba(55, 30, 118, 0.17) 12.64%,
            rgba(68, 31, 138, 0.38) 34.25%,
            rgba(87, 31, 167, 0.67) 59.97%,
            #6c20c9 86.73%
          )
        );
      }
      > div .item {
        width: 100%;
        aspect-ratio: 1/0.44;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        span {
          font-size: 23px;
          font-weight: 700;
          color: white;
        }
      }
    }
  }
  .lower {
    .row {
      > div {
        .item {
          header {
            margin-top: -50px;
          }
          background: #c0bbd1;
          padding: 20px;
          aspect-ratio: 1/0.3;
          border-radius: 25px;
          p {
            font-size: 19px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 5; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  .requirement {
    header {
      margin-bottom: 40px;
    }
    .right {
      .element {
        color: #c0bbd1;
        align-items: center;
        svg {
          margin-left: 20px;
          font-size: 22px;
        }
        span {
          font-size: 22px;
          font-weight: 400;
        }
      }
      .element:nth-of-type(1) {
        margin-left: 40px;
      }
    }
    .left {
      display: flex;
      justify-content: end;
      .element {
        margin-top: 50px;
        padding: 20px 40px 20px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          position: absolute;
          top: 0;
          left: 0;
        }
        p {
          color: $bink-color;
          font-size: 20px;
          position: relative;
          z-index: 10;
        }
      }
    }
  }
  .description {
    .left {
      padding: 20px 50px 0 0;
      position: relative;
      &::before {
        content: "";
        width: 2px;
        height: 80%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -20%);
        background: white;
      }
    }
    .right {
      padding: 0 0 50px 0;
      li {
        color: #fff;
        padding-right: 15px;
        position: relative;
        &::after {
          content: "";
          width: 5px;
          height: 5px;
          background: #fff;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .left {
      li {
        color: $green-color;
      }
    }
    ul {
      li {
        font-size: 17px;
        margin-bottom: 15px;
        span {
          color: $bink-color;
        }
        img {
          margin-left: 10px;
        }
      }
    }
  }
  .points {
    .head {
      width: 250px;
      margin: -0 auto 30px;
      aspect-ratio: 1/0.7;
      flex-shrink: 0;
      background: url("../../assets/image7.png");
      background-size: cover;
      padding: 20px;

      h5 {
        color: white;
        font-size: 25px;
        text-align: center;
        font-weight: 700;
      }
      h5:nth-of-type(1) {
        margin-bottom: 15px;
      }
      h5:nth-of-type(2) {
        color: $bink-color;
      }
    }
    ul {
      text-align: center;
      li {
        span {
          color: $bink-color;
        }
        display: inline-block;
        color: white;
        font-size: 19px;
        margin-bottom: 15px;
        position: relative;
        padding-right: 15px;
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background: white;
          position: absolute;
          right: 0;
          top: 10px;
        }
      }
    }
  }
  footer {
    margin-top: 50px;
    position: relative;
    padding: 80px 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      position: relative;
      z-index: 20;
      text-align: center;
      h4 {
        color: #c0bbd1;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      button {
        padding: 10px 15px;
        background: white;
        color: #ee49b0;
        font-size: 18px;
        border-radius: 10px;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 700;
        font-size: 19px;
        &:hover {
          background: #ee49b0;
          color: white;
        }
      }
    }
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1500px) {
}
@media (max-width: 1300px) {
  .age-categories .lower .row > div:first-of-type {
    margin-bottom: 40px;
  }
  .age-categories .lower .row > div .item p {
    font-size: 17px;
  }
}

@media (max-width: 1000px) {
  .age-categories .lower .row > div .item p {
    font-size: 19px;
  }
  .age-categories .points ul li {
    font-size: 17px !important;
  }
  .age-categories .description .left::before {
    display: none;
  }
}

@media (max-width: 700px) {
}
@media (max-width: 400px) {
}
</style>
